"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {translate} from "@elements/translations";
import {onEnterViewPort} from "@elements/viewport-utils";
import {matches} from "@elements/dom-utils";
import Pristine from "pristinejs";
import * as reloadForm from '@elements/reload-form';
// Here the function for the datalayer.push is being imported
import {pushDatalayer} from "./form-tracking";


const defaultOptions = {
    classTo: 'form-group',
    errorClass: 'has-error',
    successClass: 'has-success',
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'form-errors invalid-feedback'
};

const defaultSelectors = {
    base: '.js-form-validation'
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFormValidation(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFormValidation(form, options, selectors) {

    onEnterViewPort(form, function () {
        //Add custom validator for not only number inputs
        Pristine.addValidator('not-only-number', function (value) {
            return isNaN(value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, ''));
        }, translate('only-number-error'), 2, false);

        form.pristine = new Pristine(form, options);

        form.addEventListener('submit', function (e) {
            form.pristine.reset();
            if (!form.pristine.validate()){
                console.log('not Valid', form.pristine.getErrors());
                // e.stopImmediatePropagation();
                e.preventDefault();
            }
            else {
                // If the validation is correct and the "data-form-tracking" attribute is given, the form is being tracked
                if (form.getAttribute('data-form-tracking')) {
                    // The funktion for the datalayer.push is defined in "form-tracking.js"
                    pushDatalayer(form.getAttribute('data-form-tracking'), form);
                }
            }
        });

        if (form.getAttribute('data-reload-form')){
            reloadForm.createReloadForm(form, {validator});

            function validator(form) {
                console.log('pristine',form.pristine)
                if(form.pristine && matches('.js-form-validation', form)){
                    return form.pristine.validate();
                }else{
                    return true;
                }
            }

        }

    });
}

export function validateForm(form) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        pristine.validate();
    }else{
        console.log('Using pristine without form');
    }
}


export function isValidationForm (form) {
    return matches('.js-form-validation', form);
}

export function isValid(form) {
    if(matches('form', form)){
        const pristine = new Pristine(form, defaultOptions);
        return pristine.validate();
    }else{
        return 'Using pristine without form';
    }
}
import { onFind } from '@elements/init-modules-in-scope';
import { on } from '@elements/dom-utils';

const defaultOptions = {};

export function init(options = defaultOptions){
    for (const [key, value] of Object.entries({...defaultOptions, ...options})){
        defaultOptions[key] = value;
    }

    onFind('.js-video__play', function(playButton){
        on('click', function (event) {
            playVideo(playButton.closest('.js-video'));
        }, playButton);
    });

    onFind('.js-video', function(videoContainer){
        const video = videoContainer.querySelector('video');
        video.addEventListener('pause', function(event){
            videoContainer.classList.remove('is-playing');
            videoContainer.classList.add('is-paused');
        });
        video.addEventListener('playing', function(event){
            videoContainer.classList.add('is-playing');
            videoContainer.classList.remove('is-paused');
        });
    });
}

function playVideo(videoContainer){
    videoContainer.classList.add('is-playing');
    videoContainer.classList.remove('is-paused');
    const video = videoContainer.querySelector('video');
    video.play();
}
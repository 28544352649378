import {on, off, findIn, findAllIn, closest, removeClass, addClass, hasClass} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-hotspot', function (element) {
        let toggleBtns = findAllIn('.js-hotspot__toggle', element),
            hotspots = findAllIn('.js-hotspot__hotspot-wrapper', element);
        toggleBtns.forEach((btn) => {
            let hotspotWrapper = closest('.js-hotspot__hotspot-wrapper', btn);
            on('click', (evt) => {
                if (!hasClass('is-open', hotspotWrapper)) {
                    hotspots.forEach((hotspot) => {
                        removeClass('is-open', hotspot)
                    });

                    addClass('is-open', hotspotWrapper);

                    on('click', (evt) => bodyClickHandler(evt, hotspots), document.body);
                } else {
                    removeClass('is-open', hotspotWrapper);

                    off('click', (evt) => bodyClickHandler(evt, hotspots), document.body);
                }
            }, btn);
        });

        //hotspot detail box position
        if (matchMedia('(min-width: 768px)').matches) {
            hotspots.forEach((hotspot) => {
                let detail = findIn('.js-hotspot__detail', hotspot),
                    containerBoundingRect = element.getBoundingClientRect(),
                    detailBoundingRect = detail.getBoundingClientRect();

                if (detailBoundingRect.top < containerBoundingRect.top) {
                    addClass('hotspot--detail-bottom', hotspot);
                }

                if (detailBoundingRect.right > containerBoundingRect.right) {
                    addClass('hotspot--detail-left', hotspot);
                }
            })
        }
    });

}

function bodyClickHandler(evt, hotspots) {
    if (!closest('.js-hotspot__hotspot-wrapper', evt.target)) {
        evt.preventDefault();
        hotspots.forEach((hotspot) => {
            removeClass('is-open', hotspot);
        });

        off('click', bodyClickHandler, document.body);
    }
}
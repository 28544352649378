import {closest, findAllIn, findAll, find} from '@elements/dom-utils';

const defaultOptions = {
    className:'js-mega-menu'
};
export function init(options = defaultOptions){
    for (const [key, value] of Object.entries({...defaultOptions, ...options})){
        defaultOptions[key] = value;
    }
    const megaMenuLitem = document.querySelectorAll('.'+(defaultOptions.className)+'__link');
    Array.from(megaMenuLitem).map((item, index) => {
        item.addEventListener('click', toggleSubmenu);
    });

    const megaMenuClose = document.querySelectorAll('.'+(defaultOptions.className)+'__close');
    Array.from(megaMenuClose).map((item, index) => {
        item.addEventListener('click', closeSubmenu);
    });
}
function toggleSubmenu(e){
    e.preventDefault();
    const body = document.body;
    const megaMenu = document.querySelector('.'+(defaultOptions.className));
    const listItem = closest('li',e.target);
    const list = listItem.parentElement;
    const listItemSiblings = list.querySelectorAll('li.has-submenu.is-open');
    let addIsOpenClass = true;
    if (listItem.classList.contains('is-open')){
        addIsOpenClass = false;
    }
    Array.from(listItemSiblings).map((item, index) => {
        item.classList.remove('is-open');
        const closestScrollContainer = item.closest('.js-mousemove-scroll');
        if (closestScrollContainer){
            closestScrollContainer.classList.remove('locked');
        }
    });

    if (addIsOpenClass){
        listItem.classList.add('is-open');
        const closestScrollContainer = listItem.closest('.js-mousemove-scroll');
        if (closestScrollContainer){
            closestScrollContainer.classList.add('locked');
        }
    }

    const openedItem = megaMenu.querySelector('.is-open');
    if (openedItem){
        body.classList.add('menu-open');
    } else {
        body.classList.remove('menu-open');
    }

    body.classList.remove('search-open');
    body.classList.remove('site-fixed');

}

function closeSubmenu(e){
    const listItems = findAllIn('li', find('.navigation'));
    const mousemoveScrollItems = findAll('.mousemove-scroll');

    Array.from(listItems).map((item, index) => {
        item.classList.remove('is-open');
    });

    Array.from(mousemoveScrollItems).map((item, index) => {
        item.classList.remove('locked');
    });

}
import { onFind } from '@elements/init-modules-in-scope';

export function init(){
    onFind('.js-accordion__button', function(button){
        button.addEventListener('click', toggleItem);
    });
}

function toggleItem(event){
    const button = event.target;
    const item = button.closest('.js-accordion__item');
    const container = item.closest('.js-accordion');
    const items = container.querySelectorAll('.js-accordion__item');
    Array.from(items).map(element => {
        if (item !== element){
            collapseItem(element);
        }
    });
    if (button.classList.contains('collapsed')){
        expandItem(item);
    } else {
        collapseItem(item);
    }
}

function expandItem(item){
    const header = item.querySelector('.js-accordion__header');
    const button = item.querySelector('.js-accordion__button');
    const collapse = item.querySelector('.js-accordion__collapse');
    collapse.classList.add('show');
    const collapseHeight = collapse.offsetHeight;
    collapse.style.overflow = 'hidden';
    collapse.classList.remove('show');
    collapse.style.height = '0px';
    collapse.classList.add('show');
    button.classList.remove('collapsed');
    button.setAttribute('aria-expanded', 'true');
    setTimeout(function(){
        collapse.style.height = collapseHeight+'px';
        setTimeout(function(){
            collapse.removeAttribute('style');
        }, 320);
    }, 10);
}

function collapseItem(item){
    const header = item.querySelector('.js-accordion__header');
    const button = item.querySelector('.js-accordion__button');
    const collapse = item.querySelector('.js-accordion__collapse');
    if (!button.classList.contains('collapsed')){
        button.classList.add('collapsed');
        button.setAttribute('aria-expanded', 'false');
        collapse.style.height = collapse.offsetHeight+'px';
        collapse.style.overflow = 'hidden';
        setTimeout(function(){
            collapse.style.height = '0px';
            setTimeout(function(){
                collapse.removeAttribute('style');
                collapse.classList.remove('show');
            }, 320);
        }, 10);
    }
}
import {onFind} from "@elements/init-modules-in-scope";
import {on, closest, findIn, find} from "@elements/dom-utils";
import * as formValidation from './form-validation';

export function init(){
    onFind('.js-set-required-input', function(element){

        on('change', (evt) => {
            let target = evt.target;
            let requiredStates = target.options[target.selectedIndex].getAttribute('data-required-states');
            let requiredStatesArr = JSON.parse(requiredStates);

            if(requiredStatesArr){
                requiredStatesArr.map(({inputFieldId, required, isShipping})=>{
                    onFind('#' + inputFieldId, function(element){
                        let formGroup = closest('.form-group', element);
                        let requiredLabel = findIn('.js-form-input-required', formGroup);


                        console.log('_config.isShippingOpen',_config.isShippingOpen);

                        let changeRequired = required !== element.required;

                        if ((isShipping && _config.isShippingOpen) || !isShipping){
                            if (required){
                                element.required = true;
                                if(requiredLabel){
                                    requiredLabel.hidden = false;
                                }
                                element.setAttribute('data-set-required-input', 'true');
                            }else{
                                element.required = false;
                                if(requiredLabel){
                                    requiredLabel.hidden = true;
                                }
                                element.setAttribute('data-set-required-input', 'false');
                            }

                            if (changeRequired){
                                console.log(element);
                                closest('.js-form-validation', element).pristine.destroy();
                                formValidation.init();
                            }
                        }else{
                            element.required = false;
                            if(requiredLabel){
                                requiredLabel.hidden = true;
                            }
                            if (required){
                                element.setAttribute('data-set-required-input', 'true');
                            }else{
                                element.setAttribute('data-set-required-input', 'false');
                            }
                        }

                        if (!find('#requiredStates')){
                            let input = document.createElement("input");
                            input.type = "hidden";
                            input.id = "requiredStates";
                            input.name = "requiredStates";
                            input.value = requiredStates;

                            target.parentNode.insertBefore(input, target.nextSibling);
                        }else{
                            find('#requiredStates').value = requiredStates;
                        }
                    });
                });
            }
        }, element)

    });
}


export function fixedContent(open){
	const site = document.getElementsByClassName('site')[0];
	const body = document.body;
	const currentScrollTop = window.scrollY;
	if (open && !body.classList.contains('site-fixed')){
		body.classList.add('site-fixed');
		site.style.marginTop = (currentScrollTop*-1)+'px';
		body.scrollTop = 0;
		document.documentElement.scrollTop = 0;
	} else if (!open && body.classList.contains('site-fixed')){
		const newScrollTop = parseInt(site.style.marginTop);
		site.removeAttribute('style');
		body.classList.remove('site-fixed');
		body.scrollTop = newScrollTop*-1;
		document.documentElement.scrollTop = newScrollTop*-1;
	}
}

export function isTouchDevice(){
	return 'ontouchstart' in document.documentElement;
}

export function buttonLoading(button, loading){
    if (loading){
		const loader = document.createElement('div');
		loader.classList.add('spinner', 'spinner--xs');
		const loaderWrapper = document.createElement('span');
		loaderWrapper.classList.add('btn__icon');
		loaderWrapper.appendChild(loader);
		button.appendChild(loaderWrapper);
		button.setAttribute('disabled', 'disabled');
	} else {
		button.removeAttribute('disabled');
		if (button.querySelector('.btn__icon')){
			button.querySelector('.btn__icon').remove();
		}
	}
}

export function animatedTeaser(elements){
    Array.from(elements).map((item, index) => {
        setTimeout(function(){
            item.classList.remove('animated');
        }, index*100);
    });
}
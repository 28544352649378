import { onFind } from '@elements/init-modules-in-scope';

const defaultOptions = {};

export function init(options = defaultOptions){
    for (const [key, value] of Object.entries({...defaultOptions, ...options})){
        defaultOptions[key] = value;
    }

    if (document.querySelector('.js-navi__anchor')){
        document.body.classList.add('has-anchor');
    }

    onFind('.js-navi__burger', function(burger){
        burger.addEventListener('click', function(e){
            const navi = burger.closest('.js-navi');
            navi.classList.toggle('is-open');
        });
    });

    onFind('.js-navi__toggle-submenu', function(toggleButton){
        toggleButton.addEventListener('click', function(e){
            const listItem = toggleButton.closest('li');
            listItem.classList.toggle('is-selected');
        });
    });
}
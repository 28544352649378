const defaultOptions = {
    className:'js-scroll-navi',
    enableAnimations:true,
    enableAnimationsMinWidth:false
};
let iScrollPos = 0;
export function init(options = defaultOptions){
    removeEventListener();
    for (const [key, value] of Object.entries({...defaultOptions, ...options})){
        defaultOptions[key] = value;
    }
    window.addEventListener('load', naviHandler);
    window.addEventListener('resize', naviHandler);
    document.addEventListener('scroll', naviHandler);
}
function naviHandler(e){
    const windowEl = window;
    const iCurScrollPos = windowEl.scrollY;
    const navBar = document.querySelector('.'+defaultOptions.className);

    if (iCurScrollPos > iScrollPos){
        if (iCurScrollPos > navBar.offsetHeight){
            if (!navBar.classList.contains('is-scrolled')){
                navBar.classList.add('has-no-animation');
            }
            if (e.type != 'load'){
                navBar.classList.add('is-hidden');
                document.querySelector('body').classList.remove('navbar-is-visible');
            }
        }
    } else {
        navBar.classList.remove('is-hidden');
        document.querySelector('body').classList.add('navbar-is-visible');

    }
    iScrollPos = iCurScrollPos;
    if (defaultOptions.enableAnimations && (!defaultOptions.enableAnimationsMinWidth || windowEl.innerWidth >= defaultOptions.enableAnimationsMinWidth)){
        if (iCurScrollPos > navBar.offsetHeight){
            navBar.classList.add('is-scrolled');
            setTimeout(function(){
                navBar.classList.remove('has-no-animation');
            }, 1);
        }
        if (iCurScrollPos == 0){
            navBar.classList.remove('is-scrolled');

        }
    }
}
export function setAnimation(animate){
    removeEventListener();
    init({
        enableAnimations:animate
    });
}
function removeEventListener(){
    window.removeEventListener('load', naviHandler);
    window.removeEventListener('resize', naviHandler);
    document.removeEventListener('scroll', naviHandler);
}
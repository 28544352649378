import { isTouchDevice } from './utils';

const defaultOptions = {
    className:'js-mousemove-scroll',
    offset:0
};
export function init(options = defaultOptions){
    for (const [key, value] of Object.entries({...defaultOptions, ...options})){
        defaultOptions[key] = value;
    }
    const scrollContainerItems = document.querySelectorAll('.'+defaultOptions.className);
    Array.from(scrollContainerItems).map((scrollContainer, scrollContainerIndex) => {
        const scrollWrapper = scrollContainer.querySelector('.'+(defaultOptions.className)+'__wrapper');
        if (!isTouchDevice()){
            scrollContainer.addEventListener('mousemove', (e) => mouseMoveHandler(scrollContainer, scrollWrapper, e));
            window.addEventListener('resize', (e) => mouseMoveHandler(scrollContainer, scrollWrapper, e));
        }
    });
}
function mouseMoveHandler(scrollContainer, scrollWrapper, e){
    if (!isTouchDevice()){
        const offset = defaultOptions.offset;
        const containerHeight = scrollContainer.offsetHeight;
        const containerHeightOffset = scrollContainer.offsetHeight-(offset*2);
        const wrapperHeight = scrollWrapper.offsetHeight;
        const mousePosition = e.clientY-scrollContainer.getBoundingClientRect().top-offset;
        const diff = wrapperHeight-containerHeight;
        const percentage = (100*diff)/wrapperHeight;
        const translateY = ((percentage*mousePosition)/containerHeightOffset)*-1;
        let translateYValue = 0;
        if (containerHeight < wrapperHeight){
            if (mousePosition > 0 && mousePosition+(offset*2) < containerHeight){
                translateYValue = translateY;
            } else if (mousePosition < offset){
                translateYValue = 0;
            } else {
                translateYValue = percentage*-1;
            }
        } else {
            translateYValue = 0;
        }
        const parentContainer = scrollContainer.parentNode.closest('.'+defaultOptions.className);
        if (parentContainer){
            //translateYValue = translateYValue+(parseInt(parentContainer.getAttribute('data-y-pos'))*-1);
            //scrollContainer.style.top = 'calc('+(parseInt(parentContainer.getAttribute('data-y-pos'))*-1)+'% - 50px)';
        }
        if (!scrollContainer.classList.contains('locked')){
            scrollWrapper.style.transform = 'translateY('+(translateYValue)+'%)';
            scrollContainer.setAttribute('data-y-pos', translateYValue);

            Array.from(scrollContainer.querySelectorAll('.'+defaultOptions.className)).map((innerScrollContainer, innerScrollContainerIndex) => {
                innerScrollContainer.style.top = (translateYValue*-1)+'%';
            });
        }
    }
}
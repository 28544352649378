import Swiper from 'swiper';
import SwiperCore, { Navigation } from 'swiper/core';
import {onFind} from "@elements/init-modules-in-scope";

SwiperCore.use([Navigation]);
const defaultOptions = {};

export function init(options = defaultOptions) {
    for (const [key, value] of Object.entries({...defaultOptions, ...options})) {
        defaultOptions[key] = value;
    }

    onFind('.js-video-slider', function (baseElement) {
        console.log("video-slider")
        const swiper = new Swiper('.swiper-container', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1,
            on: {
                transitionStart: function(){
                    let videos = document.querySelectorAll('video');
                    let iframes = document.querySelectorAll('iframe');

                    //asset videos
                    Array.prototype.forEach.call(videos, function(video){
                        video.pause();
                    });

                    //asset videos
                    Array.prototype.forEach.call(iframes, function(iframe){
                        console.log("iframe")
                        let iframeSrc = iframe.src;
                        iframe.src = iframeSrc;
                    })
                },
             },
        });
    })
}
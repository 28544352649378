"use strict";
import * as wishlistbundle from "./wishlist";
import {findAllIn, find, on, findIn, removeAttribute} from "@elements/dom-utils";
import {onFind, initInScope } from "@elements/init-modules-in-scope";

console.log(window['_wishlist'])
let wishlist = null;
if (window['_wishlist']) {
    wishlist = wishlistbundle.createWishlist({
        ...window['_wishlist'],
        notificationOptions: {
            $container: find('.js-wishlist__notification'),
        }
    })
}

let myWishlists = [];
if (window['_wishlists']) {
    for(let i = 0; i < window['_wishlists'].length; i++){
        myWishlists.push(wishlistbundle.createWishlist({
            ...window['_wishlists'][i],
            notificationOptions: {
                $container: find('.js-wishlist__notification'),
            }
        }, {
            item: '.js-wishlist-' + i + '__btn',
            itemId: 'wishlist-id',
            wishlistId: 'wishlist-list',
            itemActiveClass: 'is-active'
        }));
    }
}

export function init() {
    if (myWishlists.length) {
        myWishlists.forEach((wishlist) => {
            wishlist.init();
        });
    }
    if(wishlist){
        wishlist.init();
    }

    onFind('.js-wishlist', function (parent) {
        let result = findIn('.js-wishlist__result', parent);
        let containers = findAllIn('.js-wishlist__container', parent);
        let loading = findIn('.js-wishlist__loading', parent);

        containers.forEach((container) => {
            const button = findIn('.js-wishlist__remove',container),
                id= container.getAttribute('data-wishlist-id');

            const buttonClickHandler = (evt, button) => {
                evt.preventDefault();
                loading.removeAttribute('hidden');
                let _request = wishlist.remove(id, {showUndo: true});
                _request.then(function (res) {
                    return res.clone().json()
                }).then(function (data) {
                    if(data.success){
                        result.innerHTML = data.content;
                        loading.setAttribute('hidden', true);
                        initInScope();
                    }
                })
            };

            on('click', (evt) => buttonClickHandler(evt, button), button);
        });
    })
}
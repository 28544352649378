import { fixedContent } from 'js/scripts/utils';
import { onFind } from '@elements/init-modules-in-scope';
import { on, findAll, removeClass } from '@elements/dom-utils';

const defaultOptions = {};

export function init(options = defaultOptions){
    for (const [key, value] of Object.entries({...defaultOptions, ...options})){
        defaultOptions[key] = value;
    }


    onFind('.js-search-overlay__button', function(button){
        on('click', function(evt){
            const body = document.body;
            evt.preventDefault();

            if (body.classList.contains('search-open')){
                body.classList.remove('search-open');
                fixedContent(false);
            } else {
                body.classList.add('search-open');
                body.classList.remove('menu-open');
                let subeMenu = findAll('.has-submenu');

                subeMenu.forEach((item) => {
                    removeClass('is-open', item);
                });
                fixedContent(true);
            }
        }, button);
    });

    onFind('.js-search-overlay__close', function(button){
        on('click', function(evt){
            const body = document.body;
            evt.preventDefault();

            body.classList.remove('search-open');
            body.classList.remove('menu-open');
            let subeMenu = findAll('.has-submenu');

            subeMenu.forEach((item) => {
                removeClass('is-open', item);
            });
            fixedContent(false);
        }, button);
    });
}

import { onFind } from '@elements/init-modules-in-scope';
import 'bootstrap/js/dist/collapse';

import * as toc from '@elements/toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as navi from './navi';
navi.init();

import * as scrollNavi from './scroll-navi';
scrollNavi.init({
    className:'js-scroll-navi',
    enableAnimations:true,
    enableAnimationsMinWidth:false
});

import * as megaMenu from './mega-menu';
megaMenu.init();

import * as mousemoveScroll from './mousemove-scroll';
mousemoveScroll.init({
    offset:70
});

import Dropdown from 'bootstrap/js/dist/dropdown';
onFind('.js-dropdown', function(element){
    new Dropdown(element);
});

import Alert from 'bootstrap/js/dist/alert';
onFind('.alert',function(element){
    new Alert(element);
});

import { init as initNotification } from '@elements/alert-notification';
initNotification();

import * as floatingLabel from './floating-labels';
floatingLabel.init();

import * as actionChanger from '@elements/action-changer';
actionChanger.init();

import * as formValidation from './form-validation';
formValidation.init();

import * as affix from '@elements/affix';
affix.init({mediaQuery: '(min-width: 992px)'});

import * as searchOverlay from './search-overlay';
searchOverlay.init();

import * as scrollSlider from '@elements/scroll-slider';
scrollSlider.init();

import * as cart from './cart'
cart.init();

import * as accordion from './accordion';
accordion.init();

import * as setRequiredInput from './set-required-input';
setRequiredInput.init();

import * as video from './video';
video.init();

import * as consentOverlay from '@elements/consent-overlay';
consentOverlay.init({
        cmpTool: 'cookiebot',
        revalidateEvent: 'CookiebotOnConsentReady'
    });

import * as wishListInit from './wishlist-init';
wishListInit.init();

import * as videoSlider from "./video-slider";
videoSlider.init();

import Tooltip from 'bootstrap/js/dist/tooltip';
onFind('.js-tooltip', function(element){
    new Tooltip(element, { trigger:'hover' });
});

// Add tracking package to enable tracking on every page
import * as tracking from "@elements/tracking";
let options = {}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as formTracking from './form-tracking';
formTracking.init();

import * as hotspot from './hotspot';
hotspot.init();
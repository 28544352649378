"use strict";

import {addClass, removeClass, closest, findAllIn} from '@elements/dom-utils'
import {onFind} from "@elements/init-modules-in-scope";

const defaultSelectors = {
    base: '.js-floating-label'
};

export function init(selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createFloatingLabels(
            baseElement,
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createFloatingLabels(baseElement, selectors = defaultSelectors) {
    let formGroup = closest('.form-group', baseElement);

    if(baseElement.value !== null && baseElement.value.length > 0 || ((baseElement.getAttribute('placeholder') !== ("" || null)) && baseElement.getAttribute('placeholder').length > 0)){
        addClass('has-value', baseElement);

        addClass('has-value', formGroup);
        addClass('form-floating', formGroup); // for BS5
    }

    baseElement.addEventListener("propertychange", changeFunction);
    baseElement.addEventListener("input", changeFunction);
    baseElement.addEventListener("keyup", changeFunction);
    baseElement.addEventListener("change", changeFunction);
    baseElement.addEventListener("paste", changeFunction);

    function changeFunction() {
        if(baseElement.value.length === 0 && !baseElement.getAttribute("placeholder")){
            removeClass('has-value', baseElement);

            removeClass('has-value', formGroup);
            removeClass('form-floating', formGroup); // for BS5
        } else {
            addClass('has-value', baseElement);

            addClass('has-value', formGroup);
            addClass('form-floating', formGroup); // for BS5
        }
    }
}

import {getConfigValue, requireConfigValues} from '@elements/config-utils';
import {showNotification, clearAll} from '@elements/alert-notification';
import formDataEntries from 'form-data-entries';
import {
    find,
    findIn,
    findAll,
    on,
    removeAttribute,
    setAttribute,
    addClass,
    removeClass,
    closest,
    findAllIn
} from '@elements/dom-utils';
import {onFind, initInScope, cleanUpInScope} from "@elements/init-modules-in-scope";
import {getPrefixedDataSet} from "@elements/data-set-utils";
const configName = '_cartConfig';
import axios from "axios";
import Dropdown from 'bootstrap/js/dist/dropdown';

let count,
    cartListResult,
    cartContentResult,
    listLoading;

const defaultSelectors = {
    base: '.js-cart',
    form: '.js-cart__form',
    addButton: '.js-cart__add',
    removeButton: '.js-cart__remove',
    loading: '.js-cart__loading',
    result: '.js-cart__result',
    cartList: '.js-cart__list',
    cartContentResult: '.js-cart__cart-content-result',
    detailContentResult: '.js-cart__detail-content-result',
    // notifications: '.js-cart__notifications',
    count: '.js-cart__count'
};

const defaultOptions = {
    formTrigger: 'submit',
    emptyCart: false,
    onAdd: () => {},
    onAddSucceed: () => {},
    onAddFailed: () => {}
};


export function init(options = defaultOptions, selectors = defaultSelectors){

    let cartList = find(selectors.cartList);

    if (cartList){
        count = findAll(selectors.count);
        listLoading = findIn(selectors.loading, cartList);
        cartListResult = findIn(selectors.result, cartList);

        cartListResult.innerHtml = "";

        onFind(selectors.form, function (baseElement) {
            options = {
                ...defaultOptions,
                ...options,
                ...getPrefixedDataSet('cart', baseElement)
            };

            let formTrigger = options.formTrigger;

            requireConfigValues(['updateUrl'], configName);

            const handleForm = (evt) => {
                evt.preventDefault();

                let formDataEntries = getFormDataEntries([baseElement]),
                    params = new URLSearchParams(formDataEntries),
                    url = baseElement.getAttribute('data-action') || getConfigValue('updateUrl', configName);

                updateCart(url, params, baseElement, options, selectors);

                call(options.onAdd);
            }

            if (baseElement && baseElement.length) {

                baseElement.addEventListener(formTrigger, handleForm);
            }

            return {
                cleanUp: () => {
                    baseElement.removeEventListener(formTrigger, handleForm);
                }
            };
        });

        onFind(selectors.base, function (baseElement) {
            createCart(
                baseElement,
                {...defaultOptions, ...options},
                {...defaultSelectors, ...selectors}
            );
        });

        getCartInfo();
    }

}

export function createCart(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options,
        ...getPrefixedDataSet('cart', baseElement)
    };

    requireConfigValues(['infoUrl'], configName);


    findAllIn('.js-cart__item', baseElement).map((item) => {
        let productId = item.getAttribute('data-product-id');

        on('change', function (evt) {
            // if (closest('.js-cart__i-modal', evt.target)) {
            //     return;
            // }

            let params = new FormData();

            params.append('productId', productId)
            findAllIn('input, select', item).map((input) => params.append(input.name, input.value))

            params = new URLSearchParams(params);

            updateCart(getConfigValue('updateUrl', configName), params, baseElement, options, selectors);

        }, item);


        let removeButton = findIn(selectors.removeButton, item);
        if (removeButton) {
            on('click', function () {
                let params = new URLSearchParams({removeId: productId});

                updateCart(getConfigValue('updateUrl', configName), params, baseElement, options, selectors);
            }, removeButton);
        }
    });
}

export function updateCart(url, params, baseElement, options = defaultOptions, selectors = defaultSelectors) {
    let list = findIn(selectors.cartList, baseElement),
        loadingElement = findIn(selectors.loading, baseElement) !== null ? findIn(selectors.loading, baseElement) : findIn('.js-cart__loading', list),
        addButton = findIn(selectors.addButton,baseElement),
        cartContentResult = closest(selectors.cartContentResult, baseElement),
        detailContentResult = closest(selectors.detailContentResult, baseElement);


    if (loadingElement) {
        removeAttribute('hidden', loadingElement);
    }

    addClass('is-loading', baseElement);

    if(addButton){
        addButton.disabled = true;
    }

    let pendingRequest = axios({method: 'POST', url: url, data: params });

    pendingRequest.then((response) => {
        let result = response.data;

        if (result.success) {
            // cleanUpInScope(baseElement);

            // send tracking data if the response contains it
            if (result.trackingData) {
                window.dataLayer.push(result.trackingData);
            }

            //set list content
            if (result.cartListContent && cartListResult) {
                cartListResult.innerHTML = result.cartListContent;
                cleanUpInScope(baseElement);
                initInScope(cartListResult);
            }

            if (result.cartContent && cartContentResult) {
                cartContentResult.innerHTML = result.cartContent;
                cleanUpInScope(baseElement);
                initInScope(cartContentResult);
            }

            if (result.detailContent && detailContentResult) {
                detailContentResult.innerHTML = result.detailContent;
                cleanUpInScope(baseElement);
                initInScope(detailContentResult);
            }

            //set count
            if (typeof result.count !== "undefined") {
                setCount(count, result.count);
            }

            // set loading state
            addClass('is-added', baseElement);

            if(!baseElement.getAttribute('data-cart-one-add')){
                let t = setTimeout(() => {
                    removeClass('is-added', baseElement);

                    clearTimeout(t);
                }, 3000);
            }

            call(options.onAddSucceed);

            let DropdownInit = new Dropdown(find(('.js-cart-dropdown')));
            DropdownInit.show();

            let t = setTimeout(() => {
                DropdownInit.hide();

                clearTimeout(t);
            }, 4000);

        }else{
            call(options.onAddFailed);
        }
    }).catch((error) => {
        if (error.name !== 'AbortError') {
            /*Do error stuff*/
            console.error(error);
        }
    }).then(() => {
        // always executed

        if (loadingElement) {
            setAttribute('hidden', true, loadingElement);
        }

        removeClass('is-loading', baseElement);
    });

    showNotification(pendingRequest.then((x) => x.data));
}

export function getCartInfo() {
    requireConfigValues(['infoUrl'], configName);

    let request = axios({method: 'GET', url: getConfigValue('infoUrl', configName)});

    showNotification(request.then((x) => x.data));

    listLoading.hidden = false;

    request.then((response) => {
        let result = response.data;
        if (result.success) {
            if (typeof result.count !== "undefined") {
                setCount(count, result.count);
            }
            if (typeof result.cartListContent !== "undefined") {
                setCartListContent(result.cartListContent);
            }

        }
    }).catch(e => {console.warn(e);});
}

function getFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        for(let pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }
    });

    return formDataArray;
}

function setCount(elements, count) {
    if (count) {
        elements.map((item) => {
            removeAttribute('hidden', item);
            item.textContent = count;
        });
    } else {
        elements.map((item) => {
            setAttribute('hidden', true, item);
        });
    }
}

function setCartListContent(content) {
    if (content) {
        cartListResult.innerHTML = content;

        listLoading.hidden = true;
        initInScope(cartListResult);
    }
}

// Call the given function if it really is one
function call(fnc, ...params) {
    if (fnc && typeof fnc === 'function') {
        fnc(...params);
    }
}
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, findAllIn, on, off} from '@elements/dom-utils';

export function init(){
    onFind('.js-form-tracking', function(form){
        let searchBtn = findIn('.js-form-tracking__submit-btn', form);
        // The if is needed so the datalayer does not get pushed twice when the search button is clicked
        if (searchBtn) {
            on('click', () => {
                pushDatalayer(form.getAttribute('data-form-tracking'), form);
            }, searchBtn);
        }
        else {
            // Here the form is being tracked when it is being submitted
            on('submit', (e) => {
                pushDatalayer(form.getAttribute('data-form-tracking'), form);
            }, form)
        }

        trackFilterInputs(form);
    });
}

// Here to datalayer.push is called for different types of forms
// The type is determined by the "data-form-tracking" attribute
// The types differ as the datalayer parameters need to be different for each type
export function pushDatalayer (trackingFormType, form) {
    // The push only is possible if a type for the form and the consent for statistics is given
    if (Cookiebot.consent['statistics'] && trackingFormType) {
        // Here the form for the newsletter sign up is being tracked
        if (trackingFormType === 'newsletter_sign_up') {
            let target = findIn('.js-form-tracking__target', form);

            if (target) {
                // The parameters for the datalayer.push should be readable and therefore the printed text is being pushed
                window.dataLayer.push({
                    'event': trackingFormType,
                    'target_group': target.options[target.selectedIndex].text
                });
            }
        }
        // Here the contact form is being tracked
        else if (trackingFormType === 'contact_form_sent') {
            let industryGroup = findIn('.js-form-tracking__industry-group', form);
            let contactType = findIn('.js-form-tracking__contact-type', form);

            if (industryGroup && contactType) {
                // The parameters for the datalayer.push should be readable and therefore the printed text is being pushed
                window.dataLayer.push({
                    'event': trackingFormType,
                    'industry_group': industryGroup.options[industryGroup.selectedIndex].text,
                    'contact_type': contactType.options[contactType.selectedIndex].text
                });
            }
        }
        // Here the click on the newsletter form in the footer is being tracked
        else if (trackingFormType === 'click_newsletter_footer') {
            // Because only the click is being tracked, no parameters other than the event-name are needed
            window.dataLayer.push({
                'event': trackingFormType
            });
        }
        // Here the form of the product finder banner is being tracked
        else if (trackingFormType === 'product_finder_banner') {
            let location = findIn('.js-form-tracking__location:checked', form);

            // In the product finder banner you don't need to select a location, so the location parameter is optional
            window.dataLayer.push({
                'event': trackingFormType,
                'location': location ? location.id : ''
            });
        }
        else if (trackingFormType === 'search') {
            let target = findIn('.js-form-tracking__target', form);

            if (target) {
                // The datalayer should only be pushed if the search term is not empty
                if (target.value !== '') {
                    window.dataLayer.push({
                        'event': form.getAttribute('data-form-tracking'),
                        'search_term': target.value
                    });
                }
            }
        }
    }
}

// This function tracks the filter inputs when a filter is being set
function trackFilterInputs (form) {
    // Gets all inputs that should be tracked in the form
    let inputs = findAllIn('.js-form-tracking__input', form);
    const trackingFormType = form.getAttribute('data-form-tracking');

    // The change event on each input is being set
    inputs.forEach((input) => {
        // Here the input is being tracked when it is being changed
        // Depending on the input type the parameters for the datalayer.push vary
        on('change', () => {
            // The multiselect uses checkboxes as single select
            // Therefore the multiselect is being handled as a checkbox
            if (input.type === 'checkbox') {
                // Unchecking the checkbox should not be tracked
                if (input.checked) {
                    // Some labels for the multiselect checkboxes have brackets in their name, which need to be removed with regex
                    window.dataLayer.push({
                        'event': trackingFormType,
                        'filter_type': input.name.replace(/[\[\]']+/g, ''),
                        'filter_value': input.labels[0].innerText
                    });
                }
            } else if (input.tagName.toLowerCase() === 'select') {
                // The first option of the select is the placeholder and should not be tracked
                if (input.selectedIndex !== 0) {
                    // As the select value is not readable, the text of the selected option is being pushed
                    window.dataLayer.push({
                        'event': trackingFormType,
                        'filter_type': input.name,
                        'filter_value': input.options[input.selectedIndex].text
                    });
                }
            } else if (input.value !== '') {
                window.dataLayer.push({
                    'event': trackingFormType,
                    'filter_type': input.name,
                    'filter_value': input.value
                });
            }
        }, input);
    });
}